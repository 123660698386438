define("discourse/plugins/lnwTokens/initializers/lnw-tokens", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/utilities"], function (_exports, _pluginApi, _ajax, _ajaxError, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openedwindows = _exports.default = void 0;
  let openedwindows = _exports.openedwindows = [];
  const title_attr = title => ({
    "aria-label": title || null,
    title: title || null
  });
  function lnwtoken1() {
    alert("ok");
  }
  function launchBBB2($elem, tab, instant) {
    const data = $elem.data(),
      // der erste Buchstabe von data()-Feldern wird von Discourse immer lowercase gesetzt. Feldnamen müssen mehr als einen Buchstaben haben, sonst kommen die Variablen nicht an
      site = Discourse.__container__.lookup("site:main");
    // wenn site.mobileView dann optional als iframe
    // wenn isAppWebview() dann eher verpflichtend window.location.href=res.url

    $(window).off("message.kista-bbb").on("message.kista-bbb", e => {
      var $srcd = $("iframe").filter((num, dom) => dom.contentWindow === e.originalEvent.source).parents(".kista-bbb-float"),
        abtn = $srcd.find(".kista-bbb-audiobtn");
      if ($srcd.length) {
        switch (e.originalEvent.data.response) {
          case "selfMuted":
            if (!abtn.hasClass("kista-bbb-nomic")) {
              abtn.html(iconHTML("microphone-slash")).attr(title_attr("Stummgeschaltet"));
            }
            break;
          case "selfUnmuted":
            if (!abtn.hasClass("kista-bbb-nomic")) {
              abtn.html(iconHTML("microphone")).attr(title_attr("Mikrofon aktiv"));
            }
            break;
          case "joinedAudio":
            abtn.html(iconHTML("volume-off")).removeClass("kista-bbb-nomic").attr(title_attr("Nur Zuhören"));
            ifrdom.contentWindow.postMessage("c_mute_status", "*");
            break;
          case "notInAudio":
            abtn.html(iconHTML("volume-mute")).addClass("kista-bbb-nomic").attr(title_attr("Ton deaktiviert"));
            break;
          case "quit":
            $("html").removeClass("kista-bbb-float-maximized");
            $srcd.remove();
            break;
        }
        console.log($srcd.find(".kista-bbb-windowtitle").text(), e.originalEvent.data.response);
      } else {
        var srcw = openedwindows.filter((win, num) => win === e.originalEvent.source).shift();
        if (srcw) {
          switch (e.originalEvent.data.response) {
            case "quit":
              srcw.close();
              break;
          }
          console.log("ext Tab", e.originalEvent.data.response);
        }
      }
    });
    var floatremoved = 0;
    if ($('div.kista-bbb-float').length) {
      $('div.kista-bbb-float').remove();
      floatremoved = 1;
    }
    if (!floatremoved || tab) {
      if (tab) {
        if (!instant) {
          //var tabwin = window.open("about:blank","_blank");
          var tabwin = window.open();
          openedwindows.push(tabwin);
          $(tabwin.document).prop("title", data["titel"]);
          $(tabwin.document.body).css({
            "background-color": "#16384F",
            "margin": 0
          });
          var d = $('<div>').appendTo($(tabwin.document.body));
          d.css({
            "background-image": 'url("https://discourse.ivaya.customer.dyproflex.de/plugins/kistaBBB/bubbles.svg")',
            "background-size": "auto 60%",
            "background-repeat": "no-repeat",
            "background-position-x": "100%",
            width: "100%",
            height: "100%"
          });
        }
      } else {
        var d = $('<div>').addClass("kista-bbb-float");
        "nw,ne,se,sw".split(",").forEach(corner => d.append($('<div>').addClass(`kista-bbb-resize kista-bbb-resize-${corner}`).on("mousedown", e => {
          e.preventDefault();
          d.find('iframe').css('pointer-events', 'none');
          var activecorner = $(e.target),
            cursor = activecorner.css("cursor"),
            gripcoords = {
              x: e.originalEvent.clientX,
              y: e.originalEvent.clientY
            },
            start = d[0].getBoundingClientRect(),
            newdim = {
              left: start.left,
              top: start.top,
              width: start.width,
              height: start.height
            },
            bounds = {
              width: d.css('min-width').replace(/[^0-9]/g, "") || 0,
              height: d.css('min-height').replace(/[^0-9]/g, "") || 0
            };
          bounds.left = start.left + start.width - bounds.width;
          bounds.top = start.top + start.height - bounds.height;
          activecorner.addClass("kista-bbb-resize-active");
          $('body').css('cursor', cursor).on("mousemove", e => {
            var delta = {
              x: e.originalEvent.clientX - gripcoords.x,
              y: e.originalEvent.clientY - gripcoords.y
            };
            switch (corner[0]) {
              case "n":
                newdim.height = start.height - delta.y;
                newdim.top = start.top + delta.y;
                break;
              case "s":
                newdim.height = start.height + delta.y;
                break;
            }
            switch (corner[1]) {
              case "w":
                newdim.width = start.width - delta.x;
                newdim.left = start.left + delta.x;
                break;
              case "e":
                newdim.width = start.width + delta.x;
                break;
            }
            ["left", "top"].forEach(x => {
              newdim[x] = Math.min(bounds[x], newdim[x]);
            });
            ["width", "height"].forEach(x => {
              newdim[x] = Math.max(bounds[x], newdim[x]);
            });
            d.css(newdim);
          }).on("mouseup", e => {
            $('body').off("mousemove").css('cursor', 'auto');
            activecorner.removeClass("kista-bbb-resize-active");
            d.find('iframe').css('pointer-events', 'auto');
          });
        })));
        var $ifr = $('<iframe>').attr({
            "allowfullscreen": "true",
            "allow": "camera; microphone; display-capture; fullscreen; speaker"
          }),
          ifrdom = $ifr[0];
        var $volbtn = $('<div class="kista-bbb-controlbtn kista-bbb-audiobtn kista-bbb-nomic">').html(iconHTML("volume-mute")).attr(title_attr("Ton deaktiviert")).on("click", e => ifrdom.contentWindow.postMessage("c_mute", "*")),
          $minbtn = $('<div class="kista-bbb-controlbtn">').html(iconHTML("chevron-down")).attr(title_attr("Minimieren")).on("click", e => {
            $("html").removeClass("kista-bbb-float-maximized");
            d.removeClass("kista-bbb-float-maximized");
            d.toggleClass("kista-bbb-float-minimized");
            var minimized = d.hasClass("kista-bbb-float-minimized");
            $(e.currentTarget).html(iconHTML(`chevron-${minimized ? "up" : "down"}`)).attr(title_attr(minimized ? "Wiederherstellen" : "Minimieren"));
            $maxbtn.html(iconHTML("discourse-expand")).attr(title_attr("Maximieren"));
          }),
          $maxbtn = $('<div class="kista-bbb-controlbtn">').html(iconHTML("discourse-expand")).attr(title_attr("Maximieren")).on("click", e => {
            $("html").toggleClass("kista-bbb-float-maximized");
            d.toggleClass("kista-bbb-float-maximized");
            d.removeClass("kista-bbb-float-minimized");
            var maximized = d.hasClass("kista-bbb-float-maximized");
            $(e.currentTarget).html(iconHTML(`discourse-${maximized ? "compress" : "expand"}`)).attr(title_attr(maximized ? "Wiederherstellen" : "Maximieren"));
            $minbtn.html(iconHTML("chevron-down")).attr(title_attr("Minimieren"));
          }),
          $fulbtn = $('<div class="kista-bbb-controlbtn">').html(iconHTML("expand")).attr(title_attr("Vollbild")).on("click", e => {
            d.removeClass("kista-bbb-float-minimized");
            fullscreenRequest(ifrdom);
          }),
          $clsbtn = $('<div class="kista-bbb-controlbtn">').html(iconHTML("times")).attr(title_attr("Schließen")).on("click", e => {
            $("html").removeClass("kista-bbb-float-maximized");
            d.remove();
          });
        var t = $('<div>').addClass("kista-bbb-floathead").append($('<div class="kista-bbb-windowtitle">').text(data["titel"])).append($('<div class="kista-bbb-windowcontrols">').append($volbtn).append($minbtn).append($maxbtn).append($fulbtn).append($clsbtn)).appendTo(d);
        d.append($('<div>').addClass("kista-bbb-ifdiv").append($ifr));
        t.on("mousedown", e => {
          e.preventDefault();
          var p = e.target.getBoundingClientRect();
          d.find('iframe').css('pointer-events', 'none');
          var gripcoords = {
            x: e.originalEvent.x - p.left,
            y: e.originalEvent.y - p.top
          };
          $('body').on("mousemove", e => {
            d.css({
              left: e.originalEvent.clientX - gripcoords.x,
              top: e.originalEvent.clientY - gripcoords.y
            });
          }).on("mouseup", e => {
            $('body').off("mousemove");
            d.find('iframe').css('pointer-events', 'auto');
          });
        });
        t.find(".kista-bbb-controlbtn").on("mousedown", e => {
          e.preventDefault();
          e.stopPropagation();
        });
        d.appendTo('body').css({
          left: ($(window).width() - d.width()) / 2,
          top: ($(window).height() - d.height()) / 2
        }); //.css($elem.parent().offset());
      }
      var createparams = {
        "titel": data["titel"],
        "pp": data["pp"]
      };
      if (data.hasOwnProperty("sr")) {
        createparams["sr"] = data["sr"];
      }
      (0, _ajax.ajax)("/kista-bbb/create.json", {
        type: "POST",
        data: createparams
      }).then(res => {
        if (res.url) {
          if (tab) {
            if (!instant) {
              tabwin.location.href = res.url;
            } else {
              window.open(res.url, "_blank");
            }
          } else {
            d.find("iframe").attr("src", res.url);
          }
        }
      }).catch(function (error) {
        (0, _ajaxError.popupAjaxError)(error);
      });
    }
  }
  function fullscreenRequest(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else {
      return;
    }
    document.activeElement.blur();
    element.focus();
  }
  function closeBBB($elem) {
    $elem.find(".kista-bbb-ifdiv,iframe").remove();
    $elem.children().show();
  }
  function testios() {
    var ret = {
      ios: undefined,
      safari: undefined,
      standalone: undefined,
      browser: "unknown"
    };
    if (window.hasOwnProperty("navigator")) {
      if (window.navigator.hasOwnProperty("standalone")) {
        ret.standalone = window.navigator.standalone;
      }
      if (window.navigator.hasOwnProperty("userAgent") || 1) {
        var userAgent = window.navigator.userAgent;
        ret.ios = /iphone|ipod|ipad/i.test(userAgent);
        ret.safari = /safari/i.test(userAgent);
        for (var tst of [[/Firefox/, "Mozilla Firefox"], [/Opera/, "Opera"], [/OPR/, "Opera"], [/Trident/, "Microsoft Internet Explorer"], [/Edge/, "Microsoft Edg"], [/Chrome/, "Google Chrome or Chromium"], [/Safari/, "Apple Safari"]]) {
          if (tst[0].test(userAgent)) {
            ret.browser = tst[1];
            break;
          }
        }
      }
    }
    ret.isAppWebview = (0, _utilities.isAppWebview)();
    ret.isAppleDevice = (0, _utilities.isAppleDevice)();
    ret.iOSWithVisualViewport = (0, _utilities.iOSWithVisualViewport)();
    ret.isiPad = (0, _utilities.isiPad)();
    ret.safariHacksDisabled = (0, _utilities.safariHacksDisabled)();
    ret.isiOSPWA = (0, _utilities.isiOSPWA)();
    return ret;
    if (ios) {
      if (!standalone && safari) {
        //browser
      } else if (standalone && !safari) {
        //standalone
      } else if (!standalone && !safari) {
        //uiwebview
      }
      ;
    } else {
      //not iOS
    }
    ;
  }
  function attachButton($elem) {
    const data = $elem.data(),
      isDev = Discourse.currentUser.groups.filter(x => [52, 54].indexOf(x.id) >= 0).length > 0;
    let bdiv = $('<div>');
    bdiv.append(`<div class="kista-bbb-upper">Video-Konferenzraum</div><div class='kista-bbb-title'>${$elem.data("titel")}</div>`);
    bdiv.append($('<div class="kista-bbb-status">'));
    if (isDev) {
      var btest = testios(),
        dmode = "Standard";
      if (btest.ios) {
        if (btest.safari) {
          dmode = "iOS Safari";
        } else {
          dmode = "iOS WKWebView";
        }
      }
      bdiv.append($('<div class="kista-bbb-debug">').html(Object.entries(btest).map(x => `${x[0]}: <b>${x[1]}</b>`).join("<br>") + `<br><b>Modus: ${dmode}</b>`));
    }
    bdiv.append($('<div>').append($(`<button class='kista-bbb-launch btn'>${iconHTML("headset")} Jetzt teilnehmen</button>`).on("click", () => launchBBB($elem, $elem.find(".kista-bbb-launchmode").hasClass("kista-bbb-launchmode-tab"), isDev && dmode == "iOS WKWebView"))));
    if (isDev && btest.dmode == "iOS WKWebView") {} else {
      $('<div class="kista-bbb-launchmode kista-bbb-launchmode-tab">').appendTo(bdiv).append($('<div class="kista-bbb-launchmode-text kista-bbb-launchmode-text-tab">').text("Neues Tab").on("click", e => $(e.target).parent().addClass("kista-bbb-launchmode-tab"))).append($('<div class="kista-bbb-switch">').on("click", e => $(e.target).parent().toggleClass("kista-bbb-launchmode-tab"))).append($('<div class="kista-bbb-launchmode-text kista-bbb-launchmode-text-notab">').text("Bild im Bild").on("click", e => $(e.target).parent().removeClass("kista-bbb-launchmode-tab")));
    }
    $elem.empty().append($('<div class="kista-bbb-panel">').append(bdiv));
  }
  function attachStatus($elem, helper) {
    const status = $elem.find(".kista-bbb-status");
    const data = $elem.data();

    //ajax(`/kista-bbb/status/${data.meetingID}.json`).then((res) => {
    (0, _ajax.ajax)(`/kista-bbb/status.json`, {
      type: "POST",
      data: {
        titel: data["titel"]
      }
    }).then(res => {
      if (res.avatars) {
        status.html(`<span>Teilnehmer: </span>`);
        res.avatars.forEach(function (avatar) {
          status.append(`<img src="${avatar.avatar_url}" class="avatar" width="25" height="25" title="${avatar.name}" />`);
        });
      }
    });
  }
  function attachBBB($elem, helper) {
    if (helper) {
      $elem.find("[data-wrap=bbb]").each((idx, val) => {
        attachButton($(val));
        attachStatus($(val), helper);
      });
    }
  }
  function initializeLnwTokens(api) {
    // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/lib/plugin-api.js.es6
    const currentUser = api.getCurrentUser();
    const siteSettings = api.container.lookup("site-settings:main");
    const altSettings = {
      "kista_bbb_staff_only": true
    };
    let callbackwaiting = [];
    let wasloggedout = 0;
    window.lnw_token = {
      register: (type, token, success, error) => {
        $.ajax({
          url: "/lnw-tokens/register.json",
          method: "POST",
          data: {
            type: type,
            token: token
          },
          success: success,
          error: error
        });
      },
      list: (success, error) => {
        $.ajax({
          url: "/lnw-tokens/list.json",
          success: success,
          error: error
        });
      },
      login_events: callback => {
        callbackwaiting.push(callback);
      },
      loggedin: () => currentUser != null
    };

    // api.decorateCooked(attachBBB,{id: "kista-bbb"}); https://github.com/jitsi/jitsi-meet/issues/7231#issuecomment-657210849//++++TODO decorateCooked wird deprecated, prüfen ob decorateCookedElement geht https://github.com/discourse/discourse/blob/bbe5d8d5cf1220165842985c0e2cd4c454d501cd/app/assets/javascripts/discourse/app/lib/plugin-api.js#L210
    api.onPageChange((url, title) => {
      //console.log("OPC",url, title);
      wasloggedout = currentUser ? 0 : 1;
    });
    window.onbeforeunload = e => {
      var login = /\/login($|[\/?].*)/.test(e.srcElement.URL) && wasloggedout;
      var loginfo = {
        "event": login ? "login" : "logout"
      };
      //if (login) { loginfo["username"]=currentUser; loginfo["userid"]=0; }
      console.log("lnw_token_event", login ? "login" : "logout");
      callbackwaiting.filter(x => typeof x == "function").forEach(x => x(loginfo));
    };
  }
  var _default = _exports.default = {
    name: "lnw-tokens",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.0", initializeLnwTokens);
    }
  };
});